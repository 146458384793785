import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import FormRow from '../../../../components/forms/FormRow';
import { useCampaignForm } from '../../hooks/CampaignContext';

const StyledInput = styled(InputWithLabel)`
  flex: 1;
  max-width: unset;
  width: 100%;
  padding: 0 5px;
`;

const GoalInputRow = () => {
  const { t } = useTranslation('campaigns');
  const { formData, setGoal } = useCampaignForm();

  const handleChange = (value?: string) => {
    if (value) setGoal(Number.parseInt(value));
    else setGoal(undefined);
  };

  return (
    <FormRow label={t('add-campaign.goal')}>
      <StyledInput
        label={''}
        onChange={handleChange}
        value={formData.goal ? formData.goal.toString() : ''}
        inputMode={'number'}
        placeholder={t('add-campaign.goal-placeholder')}
      />
    </FormRow>
  );
};

export default GoalInputRow;
