import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';
import RectangularButton from '../buttons/RectangularButton';
import { SectionHeader } from '../typography/Headers';
import { theme } from '../../assets/styles/theme';
import { PopupContainer, PopupHeader, PopupIcon, PopupMessage } from './PopupCommons';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
`;

const ButtonYes = styled(RectangularButton)`
  font-weight: normal;
  min-width: 140px;
  height: 40px;
`;

const ButtonNo = styled(ButtonYes)`
  background-color: ${theme.color.white};
  border: 2px solid ${theme.color.red};
  color: ${theme.color.red};
`;

interface Props {
  msg: string;
  onYesClick: () => void;
  onNoClick: () => void;
}

const ConfirmPopup = (props: Props) => {
  const { t } = useTranslation();
  const { msg, onYesClick, onNoClick } = props;

  return (
    <PopupContainer>
      <PopupHeader>
        <PopupIcon>
          <HelpIcon style={{ fontSize: '35px', fill: theme.color.blue }} />
        </PopupIcon>
        <SectionHeader>{t('confirm-header')}</SectionHeader>
      </PopupHeader>
      <PopupMessage>{msg}</PopupMessage>
      <ButtonsContainer>
        <ButtonYes label={t('yes')} onClick={onYesClick} />
        <ButtonNo label={t('no')} onClick={onNoClick} />
      </ButtonsContainer>
    </PopupContainer>
  );
};

export default ConfirmPopup;
