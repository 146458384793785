import React, { useState } from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../assets/styles/theme';
import { AggregatedShipmentCostsDto, ShipmentCostReportDto } from '../../../services/Shipment/shipmentService.dto';
import { shipmentService } from '../../../services/Shipment/shipmentService';
import LoadingDialog from '../../../components/loading/LoadingDialog';
import { DEFAULT_CURRENCY } from '../../payment/utils/payment.types';
import { convertPeriod } from '../utils/sortUtils';
import { toFlatShipmentCostReport } from '../utils/agregatedReportConverter';
import { useError } from '../../../providers/useError';
import ShipmentsCostsActions from './ShipmentsCostsActions';

const MAX_PAGE_SIZE = 2000;

const StyledRow = styled(TableRow)`
  :nth-child(even) {
    background-color: ${theme.color.lighterGray};
  }
`;

const StyledCell = styled.td`
  font-family: ${theme.font.family.main};
  font-size: ${theme.font.size.xs};
  line-height: 2.2rem;
  letter-spacing: -0.4px;
  padding: 5px;
  border: 1px solid ${theme.color.lightGray};
  word-wrap: break-word;
`;

const StyledHeader = styled(StyledCell)`
  color: ${theme.color.white};
  background-color: ${theme.color.red};
  font-size: ${theme.font.size.s};
  position: sticky;
  top: 0;
  border-width: 0;
  border-right-width: 1px;
  cursor: default;

  :hover {
    text-decoration: none;
  }
`;

interface ShipmentCostColumn<T> {
  id: string;
  title: string;
  renderCell: (data: T) => JSX.Element | string | number;
}

interface Props {
  aggregatedShipmentCosts: AggregatedShipmentCostsDto[];
}

const AggregatedShipmentCostsTable = (props: Props) => {
  const { t } = useTranslation('shipments');
  const [isLoading, setIsLoading] = useState(false);
  const { handleError } = useError();

  const columns: ShipmentCostColumn<AggregatedShipmentCostsDto>[] = [
    {
      id: 'period',
      title: t('shipment-costs.columns.period'),
      renderCell: data => convertPeriod(data.period),
    },
    {
      id: 'charged',
      title: t('shipment-costs.columns.giveo-charged'),
      renderCell: data =>
        `${data.customerShipmentPrice.toFixed(2)} ${
          data.customerShipmentPriceCurrency ? data.customerShipmentPriceCurrency : DEFAULT_CURRENCY
        }`,
    },
    {
      id: 'cost',
      title: t('shipment-costs.columns.total-cost'),
      renderCell: data => `${data.carrierCost.toFixed(2)} ${DEFAULT_CURRENCY}`,
    },
    {
      id: 'action',
      title: t('shipment-costs.columns.action'),
      renderCell: data => <ShipmentsCostsActions period={data.period} onReportClick={downloadReport} />,
    },
  ];

  const downloadReport = async (period: string) => {
    setIsLoading(true);
    let lastPage;
    let currentPage = 0;
    let reportData: ShipmentCostReportDto[] = [];
    while (!lastPage) {
      const receivedData = await shipmentService
        .fetchShipmentCostsReport(convertFilters(period, currentPage))
        .then(data => data.data)
        .catch(error => {
          handleError(error, error.message);
          return null;
        });

      if (receivedData) {
        lastPage = receivedData.last;
        if (receivedData.content) reportData = reportData.concat(receivedData.content);
        currentPage++;
      } else break;
    }

    setIsLoading(false);
    const ws = XLSX.utils.json_to_sheet(toFlatShipmentCostReport(reportData));
    const wb = { Sheets: { report: ws }, SheetNames: ['report'] };
    XLSX.writeFile(wb, `shipment-cost-report-${period}.xlsx`);
  };

  const convertFilters = (reportPeriod: string, page: number) => ({
    page,
    size: MAX_PAGE_SIZE,
    sort: 'deliveryStartDate',
    reportPeriod,
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledHeader key={column.id}>{column.title}</StyledHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.aggregatedShipmentCosts.map(costs => (
              <StyledRow key={costs.period}>
                {columns.map(column => (
                  <StyledCell key={column.id}>{column.renderCell(costs)}</StyledCell>
                ))}
              </StyledRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadingDialog isOpen={isLoading} />
    </>
  );
};

export default AggregatedShipmentCostsTable;
