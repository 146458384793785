import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  CashDonationDto,
  CashDonationErrorResponseDto,
  NewCashDonationDto,
} from '../../../services/Order/orderService.dto';
import {
  NewCashTransactionDto,
  TransactionDto,
  TransactionErrorResponseDto,
} from '../../../services/Payment/paymentService.dto';
import { orderService } from '../../../services/Order/orderService';
import { paymentService } from '../../../services/Payment/paymentService';
import { CashDonationFromDialogDto } from '../utils/types';
import { useError } from '../../../providers/useError';

export const useProcessCashDonations = () => {
  const { t } = useTranslation('campaigns');
  const { errorMessage } = useError();
  const [createdDonation, setCreatedDonation] = useState<CashDonationDto>();
  const [createdTransaction, setCreatedTransaction] = useState<TransactionDto>();
  const [isProcessing, setIsProcessing] = useState(false);

  const process = (cashDonation: CashDonationFromDialogDto, onCompleted: () => void) => {
    setIsProcessing(true);
    createDonationAndTransaction(cashDonation)
      .then(() => onCompleted())
      .catch(e => errorMessage(e.message))
      .finally(() => setIsProcessing(false));
  };

  const createDonationAndTransaction = async (cashDonation: CashDonationFromDialogDto) => {
    const donation = await createDonation(cashDonation);
    await createTransaction(cashDonation, donation.id);
  };

  const createDonation = async (cashDonation: CashDonationFromDialogDto) => {
    const newDonationDto: NewCashDonationDto = {
      campaignId: cashDonation.campaignId,
      userId: cashDonation.userId,
      donationAmount: cashDonation.donationAmount,
      currency: cashDonation.currency,
      anonymous: cashDonation.anonymous,
    };
    return orderService
      .createCashDonation(newDonationDto)
      .then(response => {
        setCreatedDonation(response.data);
        return response.data;
      })
      .catch(error => {
        if (axios.isAxiosError<CashDonationErrorResponseDto>(error)) {
          throw Error(
            t('campaigns.manual-donations.order-error', {
              code: error.response?.data.code || error.response?.status,
            })
          );
        } else {
          throw error;
        }
      });
  };

  const createTransaction = async (cashDonation: CashDonationFromDialogDto, orderId: number) => {
    const newTransactionDto: NewCashTransactionDto = {
      orderId: orderId,
      cashAmount: cashDonation.donationAmount,
      currency: cashDonation.currency,
      comment: cashDonation.comment,
    };
    return paymentService
      .createCashTransaction(newTransactionDto)
      .then(response => {
        setCreatedTransaction(response.data);
        return response.data;
      })
      .catch(error => {
        if (axios.isAxiosError<TransactionErrorResponseDto>(error)) {
          throw Error(
            t('campaigns.manual-donations.payment-error', {
              code: error.response?.data.errorType || error.response?.status,
            })
          );
        } else {
          throw error;
        }
      });
  };

  return { process, createdDonation, createdTransaction, isProcessing };
};
