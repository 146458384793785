import React from 'react';
import styled from 'styled-components';
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { theme } from '../../assets/styles/theme';
import { TextSmall } from '../typography/Texts';

const Container = styled.div`
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  background-color: ${theme.color.lighterGray};
  font-size: ${theme.font.size.xs};
  font-family: ${theme.font.family.main};
  outline: 0;
  min-width: 150px;
  max-width: 200px;
  height: 40px;

  & .MuiSelect-select:focus {
    background: 0;
  }

  & .MuiSelect-icon {
    top: unset;
  }

  & :focus {
    border: unset;
  }

  & legend {
    font-size: 1.4rem;
  }
`;

const SelectedValue = styled(TextSmall)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
`;

interface Props {
  label: string;
  onChange: (values: string[]) => void;
  options: string[];
  values: string[];
  minWidth?: number;
}

const MultiChoiceWithLabel = (props: Props) => {
  const values = props.values.length > 0 ? props.values : [''];

  const handleChange = (values: string[]) => {
    props.onChange(values.filter(v => !!v));
  };

  const menuItems = () => {
    return props.options.map(option => (
      <MenuItem key={option} value={option}>
        <Checkbox style={{ color: theme.color.red }} checked={props.values.indexOf(option) > -1} />
        <TextSmall>{option}</TextSmall>
      </MenuItem>
    ));
  };

  return (
    <Container>
      <FormControl variant='outlined'>
        <InputLabel style={{ fontSize: '1.8rem' }} id='label'>
          {props.label}
        </InputLabel>
        <StyledSelect
          style={{ minWidth: props.minWidth }}
          labelId={'label'}
          onChange={e => handleChange(e.target.value as string[])}
          multiple={true}
          value={values}
          renderValue={(selected: any) => <SelectedValue>{selected.join(', ')}</SelectedValue>}
          label={props.label}>
          {menuItems()}
        </StyledSelect>
      </FormControl>
    </Container>
  );
};

export default MultiChoiceWithLabel;
