import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableRef } from '../../../../components/table/dataTableRef';
import { AdminChannelWithUserDto } from '../data/adminChannelTypes';
import { useAdminChannels } from '../data/useAdminChannels';
import DataTable, { Column } from '../../../../components/table/DataTable';
import RecipientCell from '../cells/RecipientCell';
import ChannelCell from '../cells/ChannelCell';
import SlackCell from '../cells/SlackCell';

const AdminChannelsTable = () => {
  const { t } = useTranslation('notifications');
  const { adminChannels, editUser, editSlack } = useAdminChannels();
  const dataTableRef = useRef<DataTableRef>(null);

  const columns: Column<AdminChannelWithUserDto>[] = [
    {
      id: 'notificationType',
      title: t('admin-channels.columns.notification-type'),
      align: 'center',
      renderCell: data => data.notificationType,
    },
    {
      id: 'userId',
      title: t('admin-channels.columns.user-id'),
      align: 'center',
      renderCell: data => <RecipientCell recipient={data.user} onEditClick={() => editUser(data)} />,
    },
    {
      id: 'emailChannel',
      title: t('admin-channels.columns.email-channel'),
      align: 'center',
      renderCell: data => <ChannelCell status={data.emailStatus} communicationId={data.emailToAddress} />,
    },
    {
      id: 'smsChannel',
      title: t('admin-channels.columns.sms-channel'),
      align: 'center',
      renderCell: data => <ChannelCell status={data.smsStatus} communicationId={data.smsToNumber} />,
    },
    {
      id: 'slackChannel',
      title: t('admin-channels.columns.slack-channel'),
      align: 'center',
      renderCell: data => (
        <SlackCell
          status={data.slackStatus}
          communicationId={data.slackWebhookId}
          onEditClick={() => editSlack(data)}
        />
      ),
    },
  ];

  useEffect(() => {
    dataTableRef.current?.refresh();
  }, [adminChannels]);

  const onTableStateChange = async () => ({ content: adminChannels, totalElements: adminChannels?.length });

  return (
    <DataTable ref={dataTableRef} columns={columns} onTableStateChanged={onTableStateChange} disablePagination={true} />
  );
};

export default AdminChannelsTable;
