import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import FormRow from '../../../../components/forms/FormRow';
import { useCampaignForm } from '../../hooks/CampaignContext';

const StyledInput = styled(InputWithLabel)`
  flex: 1;
  max-width: unset;
  width: 100%;
  padding: 0 5px;
`;

const NameInputRow = () => {
  const { t } = useTranslation('campaigns');
  const { formData, setName } = useCampaignForm();

  return (
    <FormRow label={t('add-campaign.name')}>
      <StyledInput label={''} onChange={setName} value={formData.name || ''} />
    </FormRow>
  );
};

export default NameInputRow;
