import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import PageContainer from '../../components/containers/PageContainer';
import RectangularButton from '../../components/buttons/RectangularButton';
import InputWithLabel from '../../components/inputs/InputWithLabel';
import { shipmentService } from '../../services/Shipment/shipmentService';
import FileUploadButton from '../../components/buttons/FileUploadButton';
import LoadingOverlay from '../../components/loading/LoadingOverlay';
import { usePopup } from '../../providers/PopupProvider';
import { useError } from '../../providers/useError';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: row;
  align-self: start;
  align-items: flex-start;
  display: flex;
  width: 100%;
`;

const GeolocationUploadButton = styled(RectangularButton)`
  height: 40px;
  margin: 5px;
`;

const Geolocation = () => {
  const { t } = useTranslation('manageContent');
  const { showSuccessPopover, showConfirmPopover } = usePopup();
  const [countryCode, setCountryCode] = useState<string>('');
  const [file, setFile] = useState<File | undefined>();
  const initUploadStatus = {
    isLoading: false,
    isSuccess: false,
  };
  const [uploadStatus, setUploadStatus] = useState(initUploadStatus);

  const { handleError } = useError();

  const resetForm = () => {
    setCountryCode('');
    setUploadStatus(initUploadStatus);
  };

  const onContinuePress = () => {
    upload();
  };

  const handleLoading = () => {
    setUploadStatus({
      isLoading: true,
      isSuccess: false,
    });
  };

  const handleSuccess = () => {
    setUploadStatus({
      isLoading: false,
      isSuccess: true,
    });
    showSuccessPopover(t('common:success'), resetForm);
  };

  const upload = () => {
    if (file && countryCode) {
      handleLoading();
      shipmentService
        .uploadGeolocationData(file, countryCode)
        .then(() => handleSuccess())
        .catch(e => {
          setUploadStatus(initUploadStatus);
          handleError(e, t('geolocation.upload-error'));
        });
    }
  };

  return (
    <PageContainer>
      <Container>
        <FileUploadButton
          label={t('geolocation.choose-file-btn')}
          onFileSelect={(file: File | undefined) => setFile(file)}
        />
        <InputWithLabel
          label={t('geolocation.country-code-input')}
          onChange={setCountryCode}
          value={countryCode}
          minWidth={200}
        />
        <GeolocationUploadButton
          label={t('geolocation.upload-btn')}
          onClick={() => showConfirmPopover(t('geolocation.confirm'), onContinuePress)}
          disabled={!file || !countryCode}
        />
      </Container>
      {uploadStatus.isLoading && <LoadingOverlay />}
    </PageContainer>
  );
};

export default Geolocation;
