import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';
import RectangularButton from '../buttons/RectangularButton';
import { SectionHeader } from '../typography/Headers';
import { theme } from '../../assets/styles/theme';
import { PopupContainer, PopupHeader, PopupIcon, PopupMessage } from './PopupCommons';

const Button = styled(RectangularButton)`
  font-weight: normal;
  width: 100%;
`;

interface Props {
  msg: string;
  onClose: () => void;
}

const ErrorPopup = (props: Props) => {
  const { t } = useTranslation();
  const { msg, onClose } = props;

  return (
    <PopupContainer>
      <PopupHeader>
        <PopupIcon>
          <ErrorIcon style={{ fontSize: '35px', fill: theme.color.red }} />
        </PopupIcon>
        <SectionHeader>{t('error-header')}</SectionHeader>
      </PopupHeader>
      <PopupMessage>{msg}</PopupMessage>
      <Button label={t('confirm-btn')} onClick={onClose} />
    </PopupContainer>
  );
};

export default ErrorPopup;
