import React, { useState } from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import { ImageDto } from '../../services/Image/useUploadImages';
import { theme } from '../../assets/styles/theme';
import InputImage from '../image/InputImage';

const Container = styled.div<{ isDragging: boolean }>`
  display: flex;
  padding: 5px;
  overflow-x: auto;
  max-width: 500px;
  border: 2px dotted ${props => (props.isDragging ? theme.color.darkerGray : theme.color.white)};
  border-radius: 10px;
`;

const PhotosContainer = styled.div`
  display: flex;
  padding: 0 5px;
  gap: 5px;
`;

const AddPhotoButton = styled.div`
  width: 100px;
  aspect-ratio: 1;
  border: 2px dotted ${theme.color.red};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgInput = styled.input`
  display: none;
`;

export const PHOTOS_COUNT_LIMIT = 12;

interface Props {
  photos: ImageDto[];
  addPhoto: (file: File) => void;
  deletePhoto: (id: string) => void;
  movePhoto: (from: number, to: number) => void;
}

const PhotosInput = ({ photos, addPhoto, deletePhoto, movePhoto }: Props) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleAddImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    Array.from(e.target.files || []).forEach(file => addPhoto(file));
    e.target.value = '';
  };

  const handleDropImg = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    Array.from(e.dataTransfer.files || []).forEach(file => addPhoto(file));
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  return (
    <Container isDragging={isDragging} onDrop={handleDropImg} onDragLeave={handleDragLeave} onDragOver={handleDragOver}>
      <label htmlFor='img-input'>
        {photos.length < PHOTOS_COUNT_LIMIT && (
          <AddPhotoButton>
            <AddIcon style={{ color: theme.color.red, fontSize: 40 }} />
          </AddPhotoButton>
        )}
      </label>
      <ImgInput id='img-input' type='file' multiple accept='image/jpeg, image/png, image/heic' onInput={handleAddImg} />
      <PhotosContainer>
        {photos.map((img, index) => (
          <InputImage
            key={img.id}
            img={img}
            onDelete={deletePhoto}
            onMoveLeft={index > 0 ? () => movePhoto(index, index - 1) : undefined}
            onMoveRight={index < photos.length - 1 ? () => movePhoto(index, index + 1) : undefined}
          />
        ))}
      </PhotosContainer>
    </Container>
  );
};

export default PhotosInput;
