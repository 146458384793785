import { AxiosPromise } from 'axios';
import { apiService, apiUrl } from '../apiService';
import { AdminChannelDto, ChannelType, ChannelStatus, UserChannel } from './notificationService.dto';

class NotificationService {
  static BASE_URL = `${apiUrl}/api/notification`;

  fetchAdminChannels(): AxiosPromise<AdminChannelDto[]> {
    const url = `${NotificationService.BASE_URL}/admin/admin-channels`;
    return apiService.get(url);
  }

  updateAdminChannelUser(notificationType: string, userId?: number): AxiosPromise<AdminChannelDto> {
    const url = `${NotificationService.BASE_URL}/admin/admin-channels/${notificationType}/user`;
    return apiService.patch(url, {}, {}, { userId });
  }

  updateAdminChannelSlack(notificationType: string, slackWebhookId?: string): AxiosPromise<AdminChannelDto> {
    const url = `${NotificationService.BASE_URL}/admin/admin-channels/${notificationType}/slack`;
    return apiService.patch(url, {}, {}, { slackWebhookId });
  }

  fetchUserChannels(userId: number): AxiosPromise<UserChannel[]> {
    const url = `${NotificationService.BASE_URL}/admin/communication-channels/${userId}`;
    return apiService.get(url);
  }

  updateUserChannel(userId: number, type: ChannelType, state: ChannelStatus) {
    const url = `${NotificationService.BASE_URL}/admin/communication-channels/${userId}/${type}`;
    return apiService.patch(url, {}, {}, { state: state });
  }
}

const notificationService = new NotificationService();

export { notificationService, NotificationService };
