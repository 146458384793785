import React from 'react';
import styled from 'styled-components';
import { theme } from '../../assets/styles/theme';

const Button = styled.button<{
  disabled?: boolean;
  isIcon?: boolean;
  backgroundColor?: string;
  borderRadius?: string;
  textColor?: string;
}>`
  font-family: ${theme.font.family.main};
  font-weight: bold;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: ${props => (props.disabled ? theme.color.disabled : props.backgroundColor)};
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  min-width: 160px;
  height: 40px;
  color: ${props => props.textColor};
  border-radius: ${props => props.borderRadius};
  border: none;
  font-size: ${theme.font.size.s};
  line-height: 122%;
  overflow: hidden;
  transition: opacity 0.3s;

  &:hover {
    opacity: ${props => (props.disabled ? 1 : 0.8)};
  }
`;

interface Props {
  onClick?: (element: HTMLButtonElement) => void;
  label: string;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: string;
  className?: string;
}

const RectangularButton = (props: Props) => {
  const defaultProps = {
    disabled: false,
    backgroundColor: theme.color.red,
    textColor: theme.color.white,
    borderRadius: '6px',
    ...props,
  };

  const { className, onClick, label } = props;

  return (
    <Button {...defaultProps} className={className} onClick={e => onClick && onClick(e.currentTarget)}>
      {label}
    </Button>
  );
};

export default RectangularButton;
