import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { paymentService } from '../../../services/Payment/paymentService';
import { TransactionErrorInfoDto } from '../../../services/Payment/paymentService.dto';
import { useError } from '../../../providers/useError';
import TransactionErrorsDetails from './TransactionErrorsDetails';

interface Props {
  transactionId: number;
}

const TransactionErrorsPopover = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorsDetails, setErrorsDetails] = useState<TransactionErrorInfoDto[]>([]);
  const { handleError } = useError();

  useEffect(() => {
    setIsLoading(true);
    paymentService
      .fetchTransactionErrors(props.transactionId)
      .then(response => {
        setErrorsDetails(response.data);
      })
      .catch(error => {
        handleError(error, error.message);
      })
      .finally(() => setIsLoading(false));
  }, [props.transactionId]);

  if (!errorsDetails || isLoading) return <LoadingIndicator />;
  return <TransactionErrorsDetails errors={errorsDetails} />;
};

export default TransactionErrorsPopover;
