import { useEffect, useState } from 'react';
import { CommentDto } from '../../../services/Campaign/campaignService.dto';
import { campaignService } from '../../../services/Campaign/campaignService';
import { useError } from '../../../providers/useError';

export const useCommentDetails = (commentId?: number, commentDto?: CommentDto) => {
  const { handleError } = useError();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState<CommentDto | undefined>(commentDto);

  useEffect(() => {
    if (!!comment || !commentId) return;
    setIsLoading(true);
    campaignService
      .fetchComment(commentId)
      .then(response => setComment(response.data))
      .catch(error => handleError(error, error.message))
      .finally(() => setIsLoading(false));
  }, [commentId, commentDto]);

  return { comment, isLoading };
};
