import styled from 'styled-components';
import { TextMain } from '../typography/Texts';

export const PopupContainer = styled.div`
  max-width: 400px;
`;

export const PopupHeader = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupMessage = styled(TextMain)`
  white-space: pre-wrap;
  padding: 10px 20px;
`;

export const PopupIcon = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
`;
