import React from 'react';
import Home from '../features/home/Home';
import Messages from '../features/messages/Messages';
import Login from '../features/account/Login';
import Tags from '../features/manageContent/Tags';
import UsersBlocked from '../features/users/UsersBlocked';
import FindUser from '../features/users/FindUser';
import UnsafeContentReports from '../features/manageContent/UnsafeContentReports';
import WithdrawMoney from '../features/payment/WithdrawMoney';
import ShipmentErrors from '../features/shipment/ShipmentErrors';
import Cemetery from '../features/maintenance/Cemetery';
import Recovery from '../features/maintenance/Recovery';
import Admins from '../features/users/Admins';
import AggregatedShipmentCosts from '../features/shipment/AggregatedShipmentCosts';
import ShipmentCosts from '../features/shipment/ShipmentCosts';
import ManualRefunds from '../features/payment/ManualRefunds';
import Campaigns from '../features/campaigns/Campaigns';
import FeatureFlags from '../features/manageContent/FeatureFlags';
import UnpaidAuctions from '../features/items/UnpaidAuctions';
import DeletedAuctions from '../features/items/DeletedAuctions';
import Fundraisers from '../features/users/Fundraisers';
import Orders from '../features/orders/Orders';
import RefundRequests from '../features/orders/RefundRequests';
import Geolocation from '../features/manageContent/Geolocation';
import Transactions from '../features/payment/Transactions';
import PaymentProviders from '../features/payment/PaymentProviders';
import Items from '../features/items/Items';
import AdminChannels from '../features/notifications/AdminChannels';

interface RoutesInterface {
  path: string;
  element: React.ReactNode;
  isPublic?: boolean;
}

export const routes: RoutesInterface[] = [
  { path: '/', element: <Home /> },
  { path: '/login', element: <Login />, isPublic: true },
  { path: '/messages/:tabName?/:conversationId?', element: <Messages /> },
  { path: '/users/blocked', element: <UsersBlocked /> },
  { path: '/users/find-user/:userId?', element: <FindUser /> },
  { path: '/users/admins', element: <Admins /> },
  { path: '/users/fundraisers/:userId?', element: <Fundraisers /> },
  { path: '/campaigns/:campaignId?', element: <Campaigns /> },
  { path: '/items/list/:itemId?', element: <Items /> },
  { path: '/items/auctions-unpaid', element: <UnpaidAuctions /> },
  { path: '/items/auctions-deleted', element: <DeletedAuctions /> },
  { path: '/payments/withdraw-money', element: <WithdrawMoney /> },
  { path: '/payments/transactions/:transactionId?', element: <Transactions /> },
  { path: '/payments/manual-refunds/:orderId?', element: <ManualRefunds /> },
  { path: '/payments/providers', element: <PaymentProviders /> },
  { path: '/maintenance/cemetery', element: <Cemetery /> },
  { path: '/maintenance/reprocessing', element: <Recovery /> },
  { path: '/shipment/error', element: <ShipmentErrors /> },
  { path: '/shipment/aggregated-costs', element: <AggregatedShipmentCosts /> },
  { path: '/shipment/costs/:period', element: <ShipmentCosts /> },
  { path: '/orders/list/:orderId?', element: <Orders /> },
  { path: '/orders/refund-requests/:orderId?', element: <RefundRequests /> },
  { path: '/manage-content/tags', element: <Tags /> },
  { path: '/manage-content/unsafe-content-reports', element: <UnsafeContentReports /> },
  { path: '/manage-content/feature-flags', element: <FeatureFlags /> },
  { path: '/manage-content/geolocation', element: <Geolocation /> },
  { path: '/notifications/admin-channels', element: <AdminChannels /> },
];
