import { FundraiserFilterDto, UserFilterDto } from '../../../services/User/userService.dto';
import { TableFilterStateDto } from '../../../components/table/DataTable';
import { phoneUtils } from '../../../utils/phoneUtils';
import { dateUtils } from '../../../utils/dateUtils';
import { parseMultiChoice } from '../../../components/table/filterConverterUtils';

export const convertFiltersToUserFilterDto = (filter: TableFilterStateDto<Map<string, any>>): UserFilterDto => {
  const phone = filter.filtersMap?.get('phoneNumber')
    ? phoneUtils.convertToPhoneDto(filter.filtersMap?.get('phoneNumber'))
    : null;
  const dateFrom =
    filter.filtersMap?.get('dateFrom') && dateUtils.toLocalDate(new Date(filter.filtersMap?.get('dateFrom')));
  const dateTo = filter.filtersMap?.get('dateTo') && dateUtils.toLocalDate(new Date(filter.filtersMap?.get('dateTo')));
  const searchedWordsList = filter.filtersMap?.get('searchValue')
    ? filter.filtersMap.get('searchValue').replaceAll(' ', ',')
    : null;
  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'created,desc',
    id: filter.filtersMap?.get('id'),
    firstName: filter.filtersMap?.get('firstName'),
    lastName: filter.filtersMap?.get('lastName'),
    email: filter.filtersMap?.get('email'),
    city: filter.filtersMap?.get('localization') || null,
    zip: filter.filtersMap?.get('zipCode') || null,
    countryCode: phone?.countryCode,
    areaCode: phone?.areaCode,
    phoneNumber: phone?.number,
    searchedWords: searchedWordsList,
    blockadeReason: filter.filtersMap?.get('blockadeReason'),
    blockadeDateFrom: dateFrom,
    blockadeDateTo: dateTo,
    status: parseMultiChoice(filter, 'status'),
  };
};

export const convertFiltersToFundraiserFilterDto = (
  filter: TableFilterStateDto<Map<string, any>>
): FundraiserFilterDto => {
  return {
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : undefined,
    id: filter.filtersMap?.get('id'),
    firstName: filter.filtersMap?.get('firstName'),
    lastName: filter.filtersMap?.get('lastName'),
    email: filter.filtersMap?.get('email'),
    fundraiserVerificationCompleted: filter.filtersMap?.get('fundraiserVerificationCompleted'),
  };
};
