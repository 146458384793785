import styled from 'styled-components';
import { Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChannelStatus } from '../../../../services/Notification/notificationService.dto';
import { theme } from '../../../../assets/styles/theme';
import { TextSmall } from '../../../../components/typography/Texts';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
`;

const StatusCheckbox = styled(Checkbox)`
  transform: scale(1.5);

  &.MuiCheckbox-root.Mui-disabled {
    color: ${props => (props.checked ? theme.color.red : theme.color.disabled)};
  }
`;

const Text = styled(TextSmall)<{ enabled: boolean }>`
  color: ${props => (props.enabled ? 'auto' : theme.color.disabled)};
`;

interface Props {
  status?: ChannelStatus;
  communicationId?: string;
}

const ChannelCell = ({ status, communicationId }: Props) => {
  const { t } = useTranslation('notifications');

  if (!status) return <Container />;

  return (
    <Container>
      {status !== 'NOT_AVAILABLE' && <StatusCheckbox checked={status === 'ENABLED'} disabled={true} />}
      <Text enabled={status === 'ENABLED'}>
        {status !== 'NOT_AVAILABLE' ? communicationId : t('admin-channels.warnings.channel-not-available')}
      </Text>
    </Container>
  );
};

export default ChannelCell;
