import { useEffect, useMemo, useState } from 'react';
import { ItemInfoDto } from '../../../services/Item/itemService.dto';
import { itemService } from '../../../services/Item/itemService';
import { auctionService } from '../../../services/Auction/auctionService';
import { AuctionDto } from '../../../services/Auction/auctionService.dto';
import { useError } from '../../../providers/useError';

export const useItemDetails = (itemId?: number, itemData?: ItemInfoDto) => {
  const { handleError } = useError();
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState<ItemInfoDto | undefined>(itemData);
  const [auction, setAuction] = useState<AuctionDto>();

  const info = useMemo(() => (!!item ? `${item.title} (#${item.id})` : ''), [item]);

  useEffect(() => {
    if (!!item || !itemId) return;
    setIsLoading(true);
    itemService
      .fetchSingleItem(itemId)
      .then(response => setItem(response))
      .catch(error => handleError(error, error.message))
      .finally(() => setIsLoading(false));
  }, [itemId, itemData]);

  useEffect(() => {
    if (!item || item.sellingMethod !== 'AUCTION') return;
    setIsLoading(true);
    auctionService
      .fetchAuctionDetails(item.id)
      .then(response => setAuction(response.data))
      .catch(error => handleError(error, error.message))
      .finally(() => setIsLoading(false));
  }, [item]);

  return { item, auction, isLoading, info };
};
