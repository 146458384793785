import React from 'react';
import { useTranslation } from 'react-i18next';
import { useItemForm } from '../../hooks/ItemContext';
import FormRow from '../../../../components/forms/FormRow';
import { FundraiserDetailsDto, UserDetailsDto } from '../../../../services/User/userService.dto';
import ConfirmedUserSelector from '../../../users/components/userSelector/ConfirmedUserSelector';

const UserInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setUser } = useItemForm();
  const { user } = formData;

  const handleUserSelected = (user?: UserDetailsDto | FundraiserDetailsDto) => {
    setUser(user as UserDetailsDto);
  };

  return (
    <FormRow label={t('add-item.user')}>
      <ConfirmedUserSelector user={user} onUserSelected={handleUserSelected} />
    </FormRow>
  );
};

export default UserInputRow;
