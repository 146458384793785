import React from 'react';
import { useTranslation } from 'react-i18next';
import { useItemForm } from '../../hooks/ItemContext';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import FormRow from '../../../../components/forms/FormRow';

const QuantityInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setQuantity } = useItemForm();
  if (formData.sellingMethod !== 'MULTIPLE') return null;

  const handleChange = (value?: string) => {
    if (value) setQuantity(Number.parseInt(value));
    else setQuantity(undefined);
  };

  return (
    <FormRow label={t('add-item.quantity')}>
      <InputWithLabel
        label={''}
        onChange={handleChange}
        value={formData.quantity ? formData.quantity.toString() : ''}
        inputMode={'number'}
      />
    </FormRow>
  );
};

export default QuantityInputRow;
