import React, { useMemo } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import BlockIcon from '@mui/icons-material/Block';
import { ImageDto } from '../../services/Image/useUploadImages';
import { theme } from '../../assets/styles/theme';
import LoadingIndicator from '../loading/LoadingIndicator';
import { usePopup } from '../../providers/PopupProvider';
import imageNotFound from '../../assets/svg/image-not-found.svg';
import { convertToThumbnail300 } from '../../utils/thumbnails';

const OptionButton = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 2px;
  border-radius: 20px;
  background-color: ${theme.color.darkGray};
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DeleteButton = styled(OptionButton)`
  top: 0;
  right: 0;
`;

const ArrowButton = styled(OptionButton)`
  width: 30px;
  height: 30px;
`;

const LeftButton = styled(ArrowButton)`
  bottom: 0;
  left: 0;
`;

const RightButton = styled(ArrowButton)`
  bottom: 0;
  right: 0;
`;

const Photo = styled.div`
  position: relative;
  width: 100px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover ${OptionButton} {
    opacity: 1;
  }
`;

const PhotoImg = styled.img<{ success: boolean }>`
  width: 100%;
  background-color: ${theme.color.white};
  border: 2px solid ${theme.color.darkGray};
  border-radius: 5px;
  opacity: ${props => (props.success ? 1 : 0.3)};
  outline: none;
  cursor: pointer;
  object-fit: cover;
  aspect-ratio: 1;
`;

const LargePhotoImg = styled(PhotoImg)`
  opacity: 1;
  max-height: 100%;
  max-width: 100%;
  width: unset;
  aspect-ratio: unset;
`;

const PhotoModal = styled(Modal)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  z-index: ${theme.zIndex.modal};
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
`;

interface Props {
  img: ImageDto;
  onDelete: (id: string) => void;
  onMoveLeft?: () => void;
  onMoveRight?: () => void;
}

const InputImage = ({ img, onDelete, onMoveLeft, onMoveRight }: Props) => {
  const { showDialog, hideDialog } = usePopup();

  const url = useMemo(() => {
    if (img.localUrl) return img.localUrl;
    if (img.url) return convertToThumbnail300(img.url);
    return imageNotFound;
  }, [img]);

  const showLargeImage = () => {
    showDialog(
      <PhotoModal open={true} onClose={hideDialog}>
        <LargePhotoImg src={url} success={img.safe && !img.isUploading} />
      </PhotoModal>
    );
  };

  return (
    <Photo>
      <PhotoImg onClick={showLargeImage} src={url} success={img.safe && !img.isUploading} />
      {img.isUploading && <StyledLoadingIndicator size={30} />}
      <DeleteButton onClick={() => onDelete(img.id)}>
        <DeleteIcon style={{ color: theme.color.red, fontSize: 25 }} />
      </DeleteButton>
      {onMoveLeft && (
        <LeftButton onClick={onMoveLeft}>
          <ArrowLeftIcon style={{ color: theme.color.red, fontSize: 20 }} />
        </LeftButton>
      )}
      {onMoveRight && (
        <RightButton onClick={onMoveRight}>
          <ArrowRightIcon style={{ color: theme.color.red, fontSize: 20 }} />
        </RightButton>
      )}
      {!img.safe && <BlockIcon style={{ position: 'absolute', color: theme.color.red, fontSize: 40 }} />}
    </Photo>
  );
};

export default InputImage;
