import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalData } from '../../../../providers/GlobalDataProvider';
import FormRow from '../../../../components/forms/FormRow';
import { useCampaignForm } from '../../hooks/CampaignContext';
import MultiChoiceWithLabel from '../../../../components/inputs/MultiChoiceWithLabel';

const CategoriesInputRow = () => {
  const { t } = useTranslation('campaigns');
  const { campaignCategories } = useGlobalData();
  const { formData, setCategories } = useCampaignForm();

  const sortedCategories = useMemo(
    () => campaignCategories.sort((a, b) => a.categoryOrder - b.categoryOrder).map(category => category.displayName),
    [campaignCategories]
  );

  const sortedSelectedCategories = useMemo(
    () =>
      formData.categories?.sort((a, b) => a.categoryOrder - b.categoryOrder)?.map(category => category.displayName) ||
      [],
    [formData.categories]
  );

  return (
    <FormRow label={t('add-campaign.categories')}>
      <MultiChoiceWithLabel
        values={sortedSelectedCategories}
        options={sortedCategories}
        label={''}
        onChange={value => setCategories(campaignCategories.filter(category => value.includes(category.displayName)))}
        minWidth={500}
      />
    </FormRow>
  );
};

export default CategoriesInputRow;
