import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import PageContainer from '../../components/containers/PageContainer';
import DateInputWithLabel, { DateTimePickerFormat } from '../../components/inputs/DateInputWithLabel';
import RectangularButton from '../../components/buttons/RectangularButton';
import InputWithLabel from '../../components/inputs/InputWithLabel';
import { RecoveryBackupDto } from '../../services/Maintenance/maintenanceService.dto';
import { maintenanceService } from '../../services/Maintenance/maintenanceService';
import { useError } from '../../providers/useError';
import { usePopup } from '../../providers/PopupProvider';
import RecoveryPasswordPopover from './components/RecoveryPasswordPopover';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: row;
  align-self: start;
  align-items: center;
  display: flex;
  width: 100%;
`;

const RecoveryButton = styled(RectangularButton)`
  height: 30px;
  margin: 5px;
`;

const Recovery = () => {
  const { t } = useTranslation('maintenance');
  const [from, setFrom] = useState<Date | null>();
  const [to, setTo] = useState<Date | null>();
  const [rabbitExchange, setExchange] = useState<string | null>();
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const { handleError } = useError();
  const { showConfirmPopover, showPopover, hidePopover } = usePopup();

  const today = new Date();
  const errorMap = new Map([
    [400, t('recovery.date-error')],
    [403, t('recovery.password-error')],
    [404, t('recovery.exchange-not-found')],
    [409, t('recovery.exchange-locked')],
    [422, t('recovery.payload-error')],
  ]);

  const resetForm = () => {
    setFrom(null);
    setTo(null);
    setExchange(null);
  };

  const onContinuePress = () => {
    showPopover(<RecoveryPasswordPopover onContinuePressed={recovery} />);
  };

  const recovery = (recoveryPassword: string) => {
    if (from && to && rabbitExchange) {
      const storedFrom = from.toISOString();
      const storedTo = to.toISOString();
      const recoveryDto: RecoveryBackupDto = {
        storedFrom,
        storedTo,
        rabbitExchange,
        recoveryPassword,
      };
      hidePopover();

      maintenanceService
        .backupRecovery(recoveryDto)
        .then(() => resetForm)
        .catch(e => handleError(e, t('recovery.default-error'), errorMap));
    }
  };

  return (
    <PageContainer>
      <Container>
        <DateInputWithLabel
          label={t('recovery.date-from')}
          onChange={setFrom}
          value={from}
          onError={setInvalidDate}
          maxDate={to!}
          pickerFormat={DateTimePickerFormat.DATE_TIME}
        />
        <DateInputWithLabel
          label={t('recovery.date-to')}
          onChange={setTo}
          value={to}
          onError={setInvalidDate}
          minDate={from!}
          maxDate={today}
          pickerFormat={DateTimePickerFormat.DATE_TIME}
        />
        <InputWithLabel
          label={t('recovery.exchange-input')}
          onChange={setExchange}
          value={rabbitExchange ? rabbitExchange : ''}
          minWidth={400}
        />
        <RecoveryButton
          label={t('recovery.recovery-btn')}
          onClick={() => showConfirmPopover(t('recovery.recovery-confirm'), onContinuePress)}
          disabled={!from || !to || !rabbitExchange || invalidDate}
        />
      </Container>
    </PageContainer>
  );
};

export default Recovery;
