import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../../../components/loading/LoadingIndicator';
import {
  ErrorMessage,
  ErrorMessages,
  FormContainer,
  FormHeader,
  FormTable,
  LoadingOverlay,
  SaveButton,
  TableWrapper,
} from '../../../../components/forms/Containers';
import { CampaignContext, CampaignContextProps, useCampaignForm } from '../../hooks/CampaignContext';
import UserInputRow from './UserInputRow';
import NameInputRow from './NameInputRow';
import CategoriesInputRow from './CategoriesInputRow';
import PhotosInputRow from './PhotosInputRow';
import VideoUrlInputRow from './VideoUrlInputRow';
import GoalInputRow from './GoalInputRow';
import TimelineInputRow from './TimelineInputRow';
import DescriptionInputRow from './DescriptionInputRow';
import LocationInputRow from './LocationInputRow';

const CampaignForm = () => {
  const { t } = useTranslation('campaigns');
  const { campaignId, errorMessages, isLoading, saveCampaign } = useCampaignForm();

  return (
    <FormContainer>
      <FormHeader>
        {!campaignId ? t('add-campaign.header-add') : t('add-campaign.header-edit', { campaignId })}
      </FormHeader>
      {isLoading && (
        <LoadingOverlay>
          <LoadingIndicator />{' '}
        </LoadingOverlay>
      )}
      <TableWrapper>
        <FormTable>
          <tbody>
            <UserInputRow />
            <NameInputRow />
            <CategoriesInputRow />
            <PhotosInputRow />
            <VideoUrlInputRow />
            <GoalInputRow />
            <TimelineInputRow />
            <DescriptionInputRow />
            <LocationInputRow />
          </tbody>
        </FormTable>
      </TableWrapper>

      <SaveButton label={t('common:save')} onClick={saveCampaign} disabled={errorMessages.length > 0} />
      {errorMessages.length > 0 && (
        <ErrorMessages>
          {errorMessages.map(msg => (
            <ErrorMessage key={msg}>{msg}</ErrorMessage>
          ))}
        </ErrorMessages>
      )}
    </FormContainer>
  );
};

const CampaignFormWithContext = ({ campaignId, onCampaignSaved }: CampaignContextProps) => {
  return (
    <CampaignContext campaignId={campaignId} onCampaignSaved={onCampaignSaved}>
      <CampaignForm />
    </CampaignContext>
  );
};

export default CampaignFormWithContext;
