import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AxiosError } from 'axios';
import { useAuth } from './AuthProvider';
import { usePopup } from './PopupProvider';

export const useError = () => {
  const { t } = useTranslation();
  const { showErrorPopover } = usePopup();
  const [isLogout, setLogout] = useState<boolean>(false);
  const { logout } = useAuth();

  const handleError = (e: AxiosError, message?: string, map?: Map<number, string>) => {
    showErrorPopover(processAxiosError(e, message, map), onClose);
  };

  const errorMessage = (message?: string) => {
    showErrorPopover(message || t('error.generic'));
  };

  const onClose = () => {
    if (isLogout) {
      logout();
      setLogout(false);
    }
  };

  const processAxiosError = (axiosError: AxiosError, message?: string, map?: Map<number, string>): string => {
    const errorStatus = axiosError.response?.status;

    if (errorStatus === 401) {
      setLogout(true);
      return t('error.unauthenticated');
    }

    if (errorStatus && map) {
      return map.get(errorStatus) || message || t('error.generic');
    }

    return message || t('error.generic');
  };

  return { handleError, errorMessage };
};
