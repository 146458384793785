import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { FormHeader } from '../../../../components/typography/PopoverTypography';
import { ConfirmButton } from '../../../../components/buttons/popoverButtons';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 480px;
  height: auto;
`;

const WebhookContainer = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
`;

const WebhookIdInput = styled(InputWithLabel)`
  width: 100%;
  padding: 0 5px;
`;

interface Props {
  notificationType: string;
  currentWebhookId?: string;
  onConfirmPress: (notificationType: string, webhookId?: string) => void;
}

const UpdateSlackForm = ({ notificationType, currentWebhookId, onConfirmPress }: Props) => {
  const { t } = useTranslation('notifications');

  const [webhookId, setSetWebhookId] = useState(currentWebhookId || '');
  const isUpdated = webhookId !== (currentWebhookId || '');

  const handleConfirmPress = () => onConfirmPress(notificationType, webhookId !== '' ? webhookId : undefined);

  return (
    <FormContainer>
      <FormHeader>{t('admin-channels.slack-form.header')}</FormHeader>
      <WebhookContainer>
        <WebhookIdInput
          label={''}
          placeholder={'T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX'}
          value={webhookId}
          onChange={setSetWebhookId}
        />
      </WebhookContainer>
      <ConfirmButton
        disabled={!isUpdated}
        label={
          !!webhookId || !isUpdated
            ? t('admin-channels.slack-form.update-button')
            : t('admin-channels.slack-form.remove-button')
        }
        onClick={handleConfirmPress}
      />
    </FormContainer>
  );
};

export default UpdateSlackForm;
