import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownWithLabel from '../../../../components/inputs/DropdownWithLabel';
import { ITEM_TYPES, ItemType } from '../../../../services/Item/itemService.dto';
import { useItemForm } from '../../hooks/ItemContext';
import FormRow from '../../../../components/forms/FormRow';

const TypeInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setType } = useItemForm();
  return (
    <FormRow label={t('add-item.type')}>
      <DropdownWithLabel
        value={formData.type}
        options={[...ITEM_TYPES]}
        label={''}
        onChange={value => setType(value as ItemType)}
        hideEmptyOption={true}
        minWidth={500}
        disabled={!!formData.id}
      />
    </FormRow>
  );
};

export default TypeInputRow;
