import React, { forwardRef, Ref, useCallback } from 'react';
import DownloadIcon from '@mui/icons-material/GetApp';
import { useTranslation } from 'react-i18next';
import DataTable, { Column, TableFilterStateDto } from '../../../components/table/DataTable';
import { ArchivedReportDto } from '../../../services/Order/orderService.dto';
import { dateUtils } from '../../../utils/dateUtils';
import { orderService } from '../../../services/Order/orderService';
import { responseDownload } from '../../../utils/downloadUtils';
import { DataTableRef } from '../../../components/table/dataTableRef';
import { useError } from '../../../providers/useError';

const WithdrawMoneyTable = forwardRef((props: {}, ref?: Ref<DataTableRef>) => {
  const { t } = useTranslation('payments');
  const { handleError } = useError();

  const columns: Column<ArchivedReportDto>[] = [
    {
      id: 'id',
      title: t('withdraw-money.columns.id'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.reportId,
    },
    {
      id: 'filename',
      title: t('withdraw-money.columns.filename'),
      isSortable: true,
      align: 'center',
      renderCell: data => data.filename,
    },
    {
      id: 'reportGenerationDate',
      title: t('withdraw-money.columns.generation-date'),
      isSortable: true,
      align: 'center',
      renderCell: data => dateUtils.formatDate(new Date(data.generationDate)),
    },
    {
      id: 'download',
      title: t('withdraw-money.columns.download'),
      align: 'center',
      renderCell: data => (
        <div title={t('withdraw-money.download-tooltip')}>
          <DownloadIcon style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => downloadReport(data.reportId)} />
        </div>
      ),
    },
  ];

  const downloadReport = (id: number) =>
    orderService
      .fetchArchivedReport(id)
      .then(responseDownload)
      .catch(e => handleError(e, t('withdraw-money.download-error')));

  const onTableStateChange = useCallback((state: TableFilterStateDto<any>) => {
    return orderService.fetchArchivedReports(convertFilters(state)).then(response => response.data);
  }, []);

  const convertFilters = (filter: TableFilterStateDto<any>) => ({
    page: filter.page,
    size: filter.pageSize,
    sort: filter.sortBy ? `${filter.sortBy},${filter.sortDirection}` : 'id,desc',
  });

  return (
    <>
      <DataTable ref={ref} columns={columns} onTableStateChanged={onTableStateChange} />
    </>
  );
});

export default WithdrawMoneyTable;
