import React from 'react';
import { useGlobalData } from '../../../../providers/GlobalDataProvider';
import { filterEmployees } from '../../utils/userSelectorQueries';
import { EmployeeWithPublicDataDto } from '../../../../providers/employeeType';
import UserSelector from './UserSelector';
import { SelectedUserType } from './userSelectorTypes';

interface Props {
  user?: SelectedUserType;
  onUserSelected: (user?: EmployeeWithPublicDataDto) => void;
}

const EmployeeSelector = ({ user, onUserSelected }: Props) => {
  const { supporters: employees } = useGlobalData();

  return (
    <UserSelector
      user={user}
      onUserSelected={onUserSelected}
      filterUsers={searchValue => filterEmployees(employees, searchValue)}
    />
  );
};

export default EmployeeSelector;
