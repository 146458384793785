import React from 'react';
import { useTranslation } from 'react-i18next';

import FormRow from '../../../../components/forms/FormRow';
import LocationInput from '../../../../components/forms/LocationInput';
import { useCampaignForm } from '../../hooks/CampaignContext';

const LocationInputRow = () => {
  const { t } = useTranslation('campaigns');
  const { formData, setCampaignLocation } = useCampaignForm();

  return (
    <FormRow label={t('add-campaign.location')}>
      <LocationInput location={formData.campaignLocation} setLocation={setCampaignLocation} />
    </FormRow>
  );
};

export default LocationInputRow;
