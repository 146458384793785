import React from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import FormRow from '../../../../components/forms/FormRow';
import { useCampaignForm } from '../../hooks/CampaignContext';

const DescriptionInputRow = () => {
  const { t } = useTranslation('campaigns');
  const { formData, setDescription } = useCampaignForm();

  return (
    <FormRow label={t('add-campaign.description')}>
      <InputWithLabel
        label={''}
        onChange={setDescription}
        value={formData.description || ''}
        multiline={true}
        rows={1}
      />
    </FormRow>
  );
};

export default DescriptionInputRow;
