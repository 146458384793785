import React from 'react';
import styled from 'styled-components';
import { TextSmall } from '../../../../components/typography/Texts';
import { theme } from '../../../../assets/styles/theme';
import ProfilePicture from '../../../../components/image/ProfilePicture';
import { SelectableUserType } from './userSelectorTypes';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: ${theme.color.lightGray};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props<T> {
  user: T;
  onClick: () => void;
}

const UsersListItem = <T extends SelectableUserType>({ user, onClick }: Props<T>) => {
  return (
    <Container onClick={onClick}>
      <ProfilePicture imageUrl={user.profileImageUrl || user.imageUrl} />
      <InfoContainer>
        <TextSmall>
          [#{user.id}] {user.firstName} {user.lastName}
        </TextSmall>
        <TextSmall>{user.email}</TextSmall>
      </InfoContainer>
    </Container>
  );
};

export default UsersListItem;
