import React from 'react';
import { useTranslation } from 'react-i18next';
import { useItemForm } from '../../hooks/ItemContext';
import DateInputWithLabel, { DateTimePickerFormat } from '../../../../components/inputs/DateInputWithLabel';
import FormRow from '../../../../components/forms/FormRow';

const AuctionEndDateInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setAuctionEndDate, auction } = useItemForm();

  if (formData.sellingMethod !== 'AUCTION') return null;
  return (
    <FormRow label={t('add-item.auction-end-date')}>
      <DateInputWithLabel
        label={''}
        pickerFormat={DateTimePickerFormat.DATE_TIME}
        value={formData.auctionEndDate}
        minDate={new Date()}
        onChange={value => setAuctionEndDate(value || undefined)}
        disabled={auction && auction.bids.length > 0}
      />
    </FormRow>
  );
};

export default AuctionEndDateInputRow;
