import React from 'react';
import { filterFundraisers } from '../../utils/userSelectorQueries';
import { FundraiserDetailsDto } from '../../../../services/User/userService.dto';
import UserSelector from './UserSelector';
import { SelectedUserType } from './userSelectorTypes';

interface Props {
  user?: SelectedUserType;
  onUserSelected: (user?: FundraiserDetailsDto) => void;
}

const FundraiserSelector = ({ user, onUserSelected }: Props) => {
  return <UserSelector user={user} onUserSelected={onUserSelected} filterUsers={filterFundraisers} />;
};

export default FundraiserSelector;
