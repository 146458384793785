import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemInfoDto } from '../../../services/Item/itemService.dto';
import ImagePreview from '../../../components/image/ImagePreview';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { useCampaignDetails } from '../../campaigns/hooks/useCampaignDetails';
import { useUserDetails } from '../../users/hooks/useUserDetails';
import { dateUtils } from '../../../utils/dateUtils';
import {
  CardContainer,
  CardCreatedInfo,
  CardHeader,
  CardTitle,
  Column,
  Grid,
  Row,
} from '../../../components/cards/CardCommons';
import CardDescription from '../../../components/cards/CardDescription';
import CardParamInfo from '../../../components/cards/CardParamInfo';
import CampaignDetailsCard from '../../campaigns/components/CampaignDetailsCard';
import UserDetailsCard from '../../users/components/UserDetailsCard';
import { formatPrice } from '../../../utils/priceFormatter';
import { formatDelivery, formatLocation } from '../../../utils/locationFormatter';
import { useItemDetails } from '../hooks/useItemDetails';

interface Props {
  itemId?: number;
  itemInfo?: ItemInfoDto;
}

const ItemDetailsCard = ({ itemId, itemInfo }: Props) => {
  const { t } = useTranslation('items');

  const { isLoading: isItemLoading, item } = useItemDetails(itemId, itemInfo);
  const { isLoading: isCampaignLoading, info: campaignInfo, campaign } = useCampaignDetails(item?.supportedCampaignId);
  const { isLoading: isOwnerLoading, info: ownerInfo, user } = useUserDetails(item?.ownerId);

  if (!item || isItemLoading)
    return (
      <CardContainer>
        <LoadingIndicator />
      </CardContainer>
    );

  const formatSellingMethod = () => {
    let txt = item.sellingMethod;
    if (item.sellingMethod === 'MULTIPLE') {
      const unitsLeft = item.unitsQuantity - item.unitsSold;
      txt += ` (${t('item-details.quantity', { unitsLeft: unitsLeft, unitsTotal: item.unitsQuantity })})`;
    }
    return txt;
  };

  return (
    <CardContainer>
      <CardHeader>{t('item-details.header', { id: item.id })}</CardHeader>
      <Row>
        <ImagePreview imageUrls={item?.imageUrlList} />
        <Column>
          <CardTitle isHidden={item.hidden}>{item.title}</CardTitle>
          <CardCreatedInfo>{`${dateUtils.formatDateDate(new Date(item.created))}`}</CardCreatedInfo>
          <CardDescription description={item.description} />
        </Column>
      </Row>
      <Grid>
        <CardParamInfo
          label={t('item-details.campaign-label')}
          value={campaignInfo}
          popover={<CampaignDetailsCard campaignId={item.supportedCampaignId} campaignAdminDto={campaign} />}
          loading={isCampaignLoading}
        />
        <CardParamInfo
          label={t('item-details.owner-label')}
          value={ownerInfo}
          popover={<UserDetailsCard userId={item.ownerId} userInfo={user} />}
          loading={isOwnerLoading}
        />
        <CardParamInfo
          label={t('item-details.price-label')}
          value={formatPrice(item.price, item.canAcceptLoHiOffers)}
        />
        <CardParamInfo
          label={t('item-details.delivery-label')}
          value={formatDelivery(item.canNationwideShipping, item.canLocalPickUpDelivery)}
        />
        <CardParamInfo label={t('item-details.category-label')} value={item.category} />
        <CardParamInfo label={t('item-details.condition-label')} value={item.condition} />
        <CardParamInfo label={t('item-details.selling-method-label')} value={formatSellingMethod()} />
        <CardParamInfo
          label={t('item-details.location-label')}
          value={formatLocation(item.itemLocation.city || '', item.itemLocation.state, item.itemLocation.zipCode)}
        />
        <CardParamInfo label={t('item-details.type-label')} value={item.itemType} />
        <CardParamInfo label={t('item-details.status-label')} value={item.itemStatus} />
      </Grid>
    </CardContainer>
  );
};

export default ItemDetailsCard;
