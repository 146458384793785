import React from 'react';
import { useTranslation } from 'react-i18next';
import { useItemForm } from '../../hooks/ItemContext';
import FormRow from '../../../../components/forms/FormRow';
import PhotosInput from '../../../../components/forms/PhotosInput';

const PhotosInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, addPhoto, deletePhoto, movePhoto } = useItemForm();

  return (
    <FormRow label={t('add-item.photos')}>
      <PhotosInput photos={formData.photos} addPhoto={addPhoto} deletePhoto={deletePhoto} movePhoto={movePhoto} />
    </FormRow>
  );
};

export default PhotosInputRow;
