import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { UserDetailsDto } from '../../../services/User/userService.dto';
import { FormHeader } from '../../../components/forms/Containers';
import { useUserChannels } from '../hooks/useUserChannels';
import { TextMain } from '../../../components/typography/Texts';
import { ChannelType } from '../../../services/Notification/notificationService.dto';
import SwitchButton from '../../../components/inputs/SwitchButton';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 320px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled(TextMain)`
  width: 100px;
  padding-left: 40px;
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

interface Props {
  user: UserDetailsDto;
}

const UserChannelsForm = ({ user }: Props) => {
  const { t } = useTranslation('users');
  const { userChannels, updateUserChannel, isLoading } = useUserChannels(user.id);
  const userInfo = useMemo(() => `${user.firstName} ${user.lastName}`, [user]);

  const handleChannelChange = (type: ChannelType, value: boolean) => {
    const state = value ? 'ENABLED' : 'DISABLED';
    updateUserChannel(user.id, type, state);
  };

  const renderSwitch = (type: ChannelType) => {
    const state = userChannels?.find(channel => channel.type === type)?.state;
    if (state === 'NOT_AVAILABLE') return <CloseIcon style={{ fontSize: '20px', marginLeft: '20px' }} />;
    return <SwitchButton checked={state === 'ENABLED'} onChange={value => handleChannelChange(type, value)} />;
  };

  return (
    <FormContainer>
      <FormHeader>{t('user-channels.header', { userInfo })}</FormHeader>
      {isLoading && (
        <LoadingOverlay>
          <LoadingIndicator />
        </LoadingOverlay>
      )}
      <Row>
        <Label>{t('user-channels.email')}</Label>
        {renderSwitch('EMAIL')}
      </Row>
      <Row>
        <Label>{t('user-channels.sms')}</Label>
        {renderSwitch('SMS')}
      </Row>
      <Row>
        <Label>{t('user-channels.push')}</Label>
        {renderSwitch('PUSH')}
      </Row>
    </FormContainer>
  );
};

export default UserChannelsForm;
