import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useItemForm } from '../../hooks/ItemContext';
import CheckboxInputWithLabel from '../../../../components/inputs/CheckboxInputWithLabel';
import DropdownWithLabel from '../../../../components/inputs/DropdownWithLabel';
import FormRow from '../../../../components/forms/FormRow';

const Container = styled.div`
  padding: 0 5px;
  display: flex;
  flex-direction: row;
`;

const weightUpToOptions = new Map<number, string>([
  [0.5, '0 - 0.5 lbs'],
  [1, '0.5 - 1 lbs'],
  [3, '1 - 3 lbs'],
  [10, '3 - 10 lbs'],
  [20, '10 - 20 lbs'],
  [30, '20 - 30 lbs'],
  [50, '30 - 50 lbs'],
  [70, '50 - 70 lbs'],
  [100, '70 - 100 lbs'],
]);

const DeliveryInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setCanLocalPickupDelivery, setCanNationwideShipping, setWeightUpTo } = useItemForm();

  const handleWeightChange = (value?: string) => {
    if (value) setWeightUpTo(Number.parseFloat(value));
    else setWeightUpTo(undefined);
  };

  if (formData.type === 'SERVICE') return null;
  return (
    <FormRow label={t('add-item.delivery')}>
      <Container>
        <CheckboxInputWithLabel
          label={t('common:delivery-pickup')}
          checked={formData.canLocalPickupDelivery}
          onChange={setCanLocalPickupDelivery}
        />
        <CheckboxInputWithLabel
          label={t('common:delivery-shipping')}
          checked={formData.canNationwideShipping}
          onChange={setCanNationwideShipping}
        />
        {formData.canNationwideShipping && (
          <DropdownWithLabel
            label={t('add-item.weight')}
            value={formData.weightUpTo?.toString()}
            onChange={handleWeightChange}
            optionsMap={weightUpToOptions}
            hideEmptyOption={true}
          />
        )}
      </Container>
    </FormRow>
  );
};

export default DeliveryInputRow;
