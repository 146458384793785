import React from 'react';
import { useTranslation } from 'react-i18next';
import ImagePreview from '../../../components/image/ImagePreview';
import LoadingIndicator from '../../../components/loading/LoadingIndicator';
import { useUserDetails } from '../../users/hooks/useUserDetails';
import {
  CardContainer,
  CardCreatedInfo,
  CardHeader,
  CardTitle,
  Column,
  Grid,
  Row,
} from '../../../components/cards/CardCommons';
import CardDescription from '../../../components/cards/CardDescription';
import CardParamInfo from '../../../components/cards/CardParamInfo';
import { CampaignAdminDto } from '../../../services/Campaign/campaignService.dto';
import { dateUtils } from '../../../utils/dateUtils';
import UserDetailsCard from '../../users/components/UserDetailsCard';
import { formatLocation } from '../../../utils/locationFormatter';
import { formatMoneyCollected } from '../../../utils/priceFormatter';
import { useCampaignDetails } from '../hooks/useCampaignDetails';

interface Props {
  campaignId?: number;
  campaignAdminDto?: CampaignAdminDto;
}

const CampaignDetailsCard = ({ campaignId, campaignAdminDto }: Props) => {
  const { t } = useTranslation('campaigns');

  const { isLoading: isCampaignLoading, campaign } = useCampaignDetails(campaignId, campaignAdminDto);
  const { isLoading: isOwnerLoading, info: ownerInfo, user } = useUserDetails(campaign?.ownerId);

  if (!campaign || isCampaignLoading)
    return (
      <CardContainer>
        <LoadingIndicator />
      </CardContainer>
    );

  return (
    <CardContainer>
      <CardHeader>{t('campaign-details.header', { id: campaign.id })}</CardHeader>
      <Row>
        <ImagePreview imageUrls={campaign?.imageUrls} />
        <Column>
          <CardTitle>{campaign.name}</CardTitle>
          <CardCreatedInfo>
            {dateUtils.formatCampaignTimeline(campaign.timelineFrom, campaign.timelineTo)}
          </CardCreatedInfo>
          <CardDescription description={campaign.description} />
        </Column>
      </Row>
      <Grid>
        <CardParamInfo
          label={t('campaign-details.owner-label')}
          value={ownerInfo}
          popover={<UserDetailsCard userId={campaign.ownerId} userInfo={user} />}
          loading={isOwnerLoading}
        />
        <CardParamInfo label={t('campaign-details.categories-label')} value={campaign.categories.join(', ')} />
        <CardParamInfo
          label={t('campaign-details.collected-label')}
          value={formatMoneyCollected(campaign.moneyCollected, campaign.moneyGoal)}
        />
        <CardParamInfo label={t('campaign-details.status-label')} value={campaign.status} />
        <CardParamInfo
          label={t('campaign-details.location-label')}
          value={formatLocation(
            campaign.campaignLocation.city || '',
            campaign.campaignLocation.state,
            campaign.campaignLocation.zipCode
          )}
        />
        <CardParamInfo label={t('campaign-details.supporters-label')} value={`${campaign.supportersNumber}`} />
      </Grid>
    </CardContainer>
  );
};

export default CampaignDetailsCard;
