import { useEffect, useMemo, useState } from 'react';
import { UserDetailsDto } from '../../../services/User/userService.dto';
import { userService } from '../../../services/User/userService';
import { useError } from '../../../providers/useError';

export const useUserDetails = (userId?: number, userData?: UserDetailsDto) => {
  const { handleError } = useError();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<UserDetailsDto | undefined>(userData);

  const info = useMemo(() => (!!user ? `${user.firstName} ${user.lastName} (#${user.id})` : ''), [user]);

  useEffect(() => {
    if (!!user || !userId) return;
    setIsLoading(true);
    userService
      .fetchSingleUser(userId)
      .then(response => setUser(response))
      .catch(error => handleError(error, error.message))
      .finally(() => setIsLoading(false));
  }, [userId, userData]);

  return { user, isLoading, info };
};
