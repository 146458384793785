import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import { FundraiserDetailsDto } from '../../../services/User/userService.dto';
import { validateCampaignForm } from '../utils/validators';
import { useFundraiserDetails } from '../../users/hooks/useFundraiserDetails';
import { CampaignCategoryDto } from '../../../services/Campaign/campaignService.dto';
import { useGlobalData } from '../../../providers/GlobalDataProvider';
import { ImageDto, useUploadImages } from '../../../services/Image/useUploadImages';
import { UploadContext } from '../../../services/utils/imageUploadService';
import { dateUtils } from '../../../utils/dateUtils';
import { DEFAULT_OBJECT_LOCATION, LocationInfoDto } from '../../../services/Shipment/shipmentService.dto';
import { convertFormDataToEditCampaignDto, convertFormDataToNewCampaignDto } from '../utils/converters';
import { campaignService } from '../../../services/Campaign/campaignService';
import { useError } from '../../../providers/useError';
import { useCampaignDetails } from './useCampaignDetails';

export interface CampaignFormData {
  id?: number;
  user?: FundraiserDetailsDto;
  name?: string;
  categories?: CampaignCategoryDto[];
  photos: ImageDto[];
  videoUrl?: string;
  goal?: number;
  timelineFrom?: Date;
  timelineTo?: Date;
  description?: string;
  campaignLocation: LocationInfoDto;
}

interface CampaignContextType {
  formData: CampaignFormData;
  campaignId?: number;
  setUser: (user?: FundraiserDetailsDto) => void;
  setName: (name?: string) => void;
  setCategories: (categories?: CampaignCategoryDto[]) => void;
  addPhoto: (file: File) => void;
  deletePhoto: (id: string) => void;
  movePhoto: (from: number, to: number) => void;
  setVideoUrl: (videoUrl?: string) => void;
  setGoal: (goal?: number) => void;
  setTimelineFrom: (timelineFrom?: Date) => void;
  setTimelineTo: (timelineTo?: Date) => void;
  setDescription: (description?: string) => void;
  setCampaignLocation: (campaignLocation: LocationInfoDto) => void;
  errorMessages: string[];
  isLoading: boolean;
  saveCampaign: () => void;
}

const context = React.createContext<CampaignContextType | null>(null);

export interface CampaignContextProps extends PropsWithChildren {
  campaignId?: number;
  onCampaignSaved: () => void;
}

const CampaignContext = ({ campaignId, onCampaignSaved, children }: CampaignContextProps) => {
  const { errorMessage } = useError();
  const { campaignCategories } = useGlobalData();

  const { campaign } = useCampaignDetails(campaignId);
  const { user: currentUser } = useFundraiserDetails(campaign?.ownerId);

  const [isLoading, setIsLoading] = useState(!!campaignId && !campaign);

  const [user, setUser] = useState<FundraiserDetailsDto>();
  const [name, setName] = useState<string>();
  const [categories, setCategories] = useState<CampaignCategoryDto[]>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [goal, setGoal] = useState<number>();
  const [timelineFrom, setTimelineFrom] = useState<Date | undefined>(new Date());
  const [timelineTo, setTimelineTo] = useState<Date>();
  const [description, setDescription] = useState<string>();
  const [campaignLocation, setCampaignLocation] = useState<LocationInfoDto>(DEFAULT_OBJECT_LOCATION);

  const { photos, setPhotoUrls, addPhoto, deletePhoto, movePhoto } = useUploadImages(
    UploadContext.CAMPAIGN,
    campaign?.imageUrls
  );

  useEffect(() => {
    if (campaign) {
      setName(campaign.name);
      setCategories(campaignCategories.filter(category => campaign.categories.includes(category.code)));
      setPhotoUrls(campaign.imageUrls);
      setVideoUrl(campaign.videoUrl);
      setGoal(campaign.moneyGoal);
      setTimelineFrom(dateUtils.parseBackendDate(campaign.timelineFrom));
      setTimelineTo(dateUtils.parseBackendDate(campaign.timelineTo));
      setDescription(campaign.description);
      setCampaignLocation(campaign.campaignLocation);
    }
  }, [campaign]);

  useEffect(() => setUser(currentUser), [currentUser]);

  useEffect(() => {
    if (campaignId) {
      setIsLoading(!campaign || !currentUser);
    }
  }, [campaignId, campaign, currentUser]);

  const saveCampaign = () => {
    setIsLoading(true);
    if (!campaignId) addCampaign();
    else editCampaign(campaignId);
  };

  const addCampaign = () => {
    const newCampaignDto = convertFormDataToNewCampaignDto(formData);
    if (!formData.user) return;
    campaignService
      .addCampaignByEmployee(newCampaignDto, formData.user.id)
      .then(() => onCampaignSaved())
      .catch(e => errorMessage(e?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  const editCampaign = (campaignId: number) => {
    const editCampaignDto = convertFormDataToEditCampaignDto(formData, campaignId);
    campaignService
      .editCampaignByEmployee(editCampaignDto)
      .then(() => onCampaignSaved())
      .catch(e => errorMessage(e?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  const formData: CampaignFormData = {
    id: campaignId,
    user,
    name,
    categories,
    photos,
    videoUrl,
    goal,
    timelineFrom,
    timelineTo,
    description,
    campaignLocation,
  };

  const errorMessages = useMemo(() => validateCampaignForm(formData, undefined), [formData, campaign]);

  return (
    <context.Provider
      value={{
        campaignId,
        formData,
        setUser,
        setName,
        setCategories,
        addPhoto,
        deletePhoto,
        movePhoto,
        setVideoUrl,
        setGoal,
        setTimelineFrom,
        setTimelineTo,
        setDescription,
        setCampaignLocation,
        errorMessages,
        isLoading,
        saveCampaign,
      }}>
      {children}
    </context.Provider>
  );
};

const useCampaignForm = () => {
  const campaignContext = useContext(context);
  if (campaignContext == null) {
    throw new Error('useCampaignForm() called outside of an CampaignContext?');
  }
  return campaignContext;
};

export { CampaignContext, useCampaignForm };
