import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '../../assets/styles/theme';
import PageContainer from '../../components/containers/PageContainer';
import { AggregatedShipmentCostsDto } from '../../services/Shipment/shipmentService.dto';
import { shipmentService } from '../../services/Shipment/shipmentService';
import { TextMain } from '../../components/typography/Texts';
import { useError } from '../../providers/useError';
import { sortDesc, filterAggregatedShipmentCosts } from './utils/sortUtils';
import ShipmentCostFilters from './components/ShipmentCostFilters';
import AggregatedShipmentCostsTable from './components/AggregatedShipmentCostsTable';

const Container = styled.div`
  padding: 10px ${theme.paddingStandard}px 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 80%;
  margin: 10px auto auto;
`;

const AggregatedShipmentCosts = () => {
  const { t } = useTranslation('shipments');
  const [aggregatedShipmentCostsDto, setAggregatedShipmentCostsDto] = useState<AggregatedShipmentCostsDto[]>();
  const [aggregatedShipmentCosts, setAggregatedShipmentCosts] = useState<AggregatedShipmentCostsDto[]>([]);
  const [from, setFrom] = useState<Date | null>(null);
  const [to, setTo] = useState<Date | null>(null);
  const { handleError } = useError();

  useEffect(() => {
    shipmentService
      .fetchAggregatedShipmentCosts()
      .then(response => {
        setAggregatedShipmentCostsDto(sortDesc(response.data));
      })
      .catch(error => handleError(error));
  }, []);

  useEffect(() => {
    if (aggregatedShipmentCostsDto) setAggregatedShipmentCosts(aggregatedShipmentCostsDto);
  }, [aggregatedShipmentCostsDto]);

  const onApply = () => {
    if (aggregatedShipmentCostsDto)
      setAggregatedShipmentCosts(filterAggregatedShipmentCosts(aggregatedShipmentCostsDto, from, to));
  };

  return (
    <PageContainer>
      <Container>
        <ShipmentCostFilters from={from} setFrom={setFrom} setTo={setTo} to={to} onApply={onApply} />
        <TextMain>{t('shipment-costs.price-message')}</TextMain>
        <AggregatedShipmentCostsTable aggregatedShipmentCosts={aggregatedShipmentCosts} />
      </Container>
    </PageContainer>
  );
};

export default AggregatedShipmentCosts;
