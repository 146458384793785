import React from 'react';
import { useTranslation } from 'react-i18next';
import { useItemForm } from '../../hooks/ItemContext';
import InputWithLabel from '../../../../components/inputs/InputWithLabel';
import FormRow from '../../../../components/forms/FormRow';

const TitleInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setTitle } = useItemForm();

  return (
    <FormRow label={t('add-item.title')}>
      <InputWithLabel label={''} onChange={setTitle} value={formData.title || ''} />
    </FormRow>
  );
};

export default TitleInputRow;
