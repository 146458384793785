import { useEffect, useState } from 'react';
import { uniq } from 'lodash';
import { AdminChannelDto } from '../../../../services/Notification/notificationService.dto';
import { notificationService } from '../../../../services/Notification/notificationService';
import { usePopup } from '../../../../providers/PopupProvider';
import UpdateRecipientForm from '../forms/UpdateRecipientForm';
import { userService } from '../../../../services/User/userService';
import UpdateSlackForm from '../forms/UpdateSlackForm';
import { AdminChannelWithUserDto, RecipientDto } from './adminChannelTypes';
import { useAdminChannelErrors } from './useAdminChannelErrors';

export const useAdminChannels = () => {
  const { handleFetchError, handleUpdateRecipientError, handleUpdateSlackError } = useAdminChannelErrors();
  const { showPopover, hidePopover } = usePopup();

  const [adminChannels, setAdminChannels] = useState<AdminChannelWithUserDto[]>();

  useEffect(() => {
    fetchAdminChannels();
  }, []);

  const fetchAdminChannels = async () => {
    try {
      const { data: adminChannels } = await notificationService.fetchAdminChannels();
      const users = await fetchUsers(adminChannels);

      const adminChannelsWithUsers = sortAdminChannels(adminChannels).map(adminChannel => ({
        ...adminChannel,
        user: users.find(user => user.id === adminChannel.userId),
      }));

      setAdminChannels(adminChannelsWithUsers);
    } catch (error: any) {
      handleFetchError(error);
    }
  };

  const fetchUsers = async (adminChannels: AdminChannelDto[]) => {
    const userIds = adminChannels
      .map(adminChannel => adminChannel.userId)
      .filter((userId): userId is number => !!userId);

    if (userIds) {
      return (await userService.fetchPublicUsers(uniq(userIds))).data;
    } else {
      return [];
    }
  };

  const editUser = (adminChannel: AdminChannelWithUserDto) =>
    showPopover(
      <UpdateRecipientForm
        notificationType={adminChannel.notificationType}
        currentRecipient={adminChannel.user}
        onConfirmPress={saveUser}
      />
    );

  const editSlack = (adminChannel: AdminChannelWithUserDto) =>
    showPopover(
      <UpdateSlackForm
        notificationType={adminChannel.notificationType}
        currentWebhookId={adminChannel.slackWebhookId}
        onConfirmPress={saveSlack}
      />
    );

  const saveUser = async (notificationType: string, user?: RecipientDto) => {
    try {
      const { data: updatedChannel } = await notificationService.updateAdminChannelUser(notificationType, user?.id);

      setAdminChannels(prevChannels =>
        prevChannels?.map(channel =>
          channel.notificationType === notificationType ? { ...updatedChannel, user } : channel
        )
      );
    } catch (error: any) {
      handleUpdateRecipientError(error);
    } finally {
      hidePopover();
    }
  };

  const saveSlack = async (notificationType: string, webhookId?: string) => {
    try {
      const { data: updatedData } = await notificationService.updateAdminChannelSlack(notificationType, webhookId);

      setAdminChannels(prevChannels =>
        prevChannels?.map(channel =>
          channel.notificationType === notificationType ? { ...updatedData, user: channel.user } : channel
        )
      );
    } catch (error: any) {
      handleUpdateSlackError(error);
    } finally {
      hidePopover();
    }
  };

  return { adminChannels, editUser, editSlack };
};

const sortAdminChannels = (adminChannels: AdminChannelDto[]) =>
  adminChannels.sort((adminChannel1, adminChannel2) =>
    adminChannel1.notificationType.localeCompare(adminChannel2.notificationType)
  );
