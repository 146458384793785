import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../assets/styles/theme';
import UsersListItem from './UsersListItem';
import { SelectableUserType } from './userSelectorTypes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 35px;
  max-height: 250px;
  background-color: ${theme.color.white};
  border-radius: 10px;
  border: 2px solid ${theme.color.lighterGray};
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px 0;
  z-index: 1;
`;

interface Props<T> {
  users?: T[];
  onUserSelected: (user: T) => void;
}

const UsersList = <T extends SelectableUserType>({ users, onUserSelected }: Props<T>) => {
  if (!users || users.length === 0) return <></>;
  return (
    <Container>
      {users.map(user => (
        <UsersListItem key={user.id} user={user} onClick={() => onUserSelected(user)} />
      ))}
    </Container>
  );
};

export default UsersList;
