import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../../../components/inputs/SearchInput';
import { TextMain } from '../../../../components/typography/Texts';
import { theme } from '../../../../assets/styles/theme';
import UsersList from './UsersList';
import { SelectableUserType, SelectedUserType } from './userSelectorTypes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: normal;
  margin: 0 5px;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled(ClearIcon)`
  cursor: pointer;
  color: ${theme.color.red};
  margin: 0 10px;
`;

interface Props<T> {
  user?: SelectedUserType;
  onUserSelected: (user?: T) => void;
  filterUsers: (searchValue: string) => Promise<T[]>;
}

const UserSelector = <T extends SelectableUserType>({ user, onUserSelected, filterUsers }: Props<T>) => {
  const { t } = useTranslation('users');
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState<T[]>([]);
  const [selectedUser, setSelectedUser] = useState(user);

  const userInfo = useMemo(() => (user ? `${user.firstName} ${user.lastName} (#${user.id})` : ''), [user]);

  const handleUserSelected = (user?: T) => {
    setSelectedUser(user);
    onUserSelected(user);
    setSearchValue('');
  };

  useEffect(() => {
    if (!searchValue) {
      setUsers([]);
    } else {
      filterUsers(searchValue).then(response => setUsers(response));
    }
  }, [searchValue]);

  useEffect(() => setSelectedUser(user), [user]);

  if (selectedUser) {
    return (
      <Container>
        <SelectedContainer>
          <TextMain>{selectedUser ? userInfo : ''}</TextMain>
          <Icon style={{ fontSize: '16px' }} onClick={() => handleUserSelected(undefined)} />
        </SelectedContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <SearchInput onChange={setSearchValue} value={searchValue} placeholder={t('search-users-placeholder')} />
        <UsersList users={users} onUserSelected={handleUserSelected} />
      </Container>
    );
  }
};

export default UserSelector;
