import React from 'react';
import styled from 'styled-components';
import ReportIcon from '@mui/icons-material/Report';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import ChatIcon from '@mui/icons-material/Chat';
import ActivateUser from '@mui/icons-material/Check';
import ActivatePhonePin from '@mui/icons-material/PhonelinkSetup';
import TwoFAPin from '@mui/icons-material/PhonelinkLock';
import DeleteForever from '@mui/icons-material/DeleteForever';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../providers/AuthProvider';
import { UserDetailsDto } from '../../../services/User/userService.dto';
import { usePopup } from '../../../providers/PopupProvider';
import UserChannelsForm from './UserChannelsForm';

const REASON_ACCOUNT_CLOSING = 'Account closing';

const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
`;

const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-self: center;
`;

interface Props {
  user: UserDetailsDto;
  onBlockPressed?: (element: HTMLDivElement) => void;
  onUnblockPressed: (element: HTMLDivElement) => void;
  onContactUser: () => void;
  onPinVerification?: (element: HTMLDivElement, user: UserDetailsDto) => void;
  onConfirmSignup?: (element: HTMLDivElement, user: UserDetailsDto) => void;
  onCloseAccount?: (element: HTMLDivElement, user: UserDetailsDto) => void;
}

const UserActions = (props: Props) => {
  const { t } = useTranslation('users');
  const { currentUser } = useAuth();
  const { showPopover } = usePopup();
  const { user } = props;

  const isActive = !user.blocked;
  const isPhoneConfirmed = user.phoneConfirmed;
  const isUserConfirmed = user.userStatus === 'CONFIRMED';
  const isClosingAccount = user.blockadeReason === REASON_ACCOUNT_CLOSING && user.userStatus !== 'DELETED';

  const displayPinVerification = () => {
    return isPhoneConfirmed ? (
      <StyledButton
        title={t('manual-verification.show-2fa-pin')}
        onClick={e => props.onPinVerification && props.onPinVerification(e.currentTarget, props.user)}>
        <TwoFAPin style={{ fontSize: 20 }} />
      </StyledButton>
    ) : (
      <StyledButton
        title={t('manual-verification.show-phone-pin')}
        onClick={e => props.onPinVerification && props.onPinVerification(e.currentTarget, props.user)}>
        <ActivatePhonePin style={{ fontSize: 20 }} />
      </StyledButton>
    );
  };

  if (user.userStatus === 'DELETED') {
    return null;
  }

  return (
    <Container>
      {isActive ? (
        <>
          <StyledButton
            title={t('block-label')}
            onClick={e => props.onBlockPressed && props.onBlockPressed(e.currentTarget)}>
            <ReportIcon style={{ fontSize: 20 }} />
          </StyledButton>
          {isUserConfirmed ? (
            <>
              {displayPinVerification()}
              {currentUser?.id !== props.user.id && (
                <StyledButton title={t('contact-label')} onClick={props.onContactUser}>
                  <ChatIcon style={{ fontSize: 20 }} />
                </StyledButton>
              )}
            </>
          ) : (
            <StyledButton
              title={t('manual-verification.activate-user')}
              onClick={e => props.onConfirmSignup && props.onConfirmSignup(e.currentTarget, props.user)}>
              <ActivateUser style={{ fontSize: 20 }} />
            </StyledButton>
          )}
        </>
      ) : (
        <StyledButton title={t('unblock-label')} onClick={e => props.onUnblockPressed(e.currentTarget)}>
          <ReportOffIcon style={{ fontSize: 20 }} />
        </StyledButton>
      )}

      <StyledButton
        title={t('user-channels.manage-channels-label')}
        onClick={e => showPopover(<UserChannelsForm user={user} />, e.currentTarget)}>
        <EditNotificationsIcon style={{ fontSize: 20 }} />
      </StyledButton>

      {props.onCloseAccount && isClosingAccount && (
        <StyledButton title={t('close-account')} onClick={e => props.onCloseAccount?.(e.currentTarget, props.user)}>
          <DeleteForever style={{ fontSize: 20 }} />
        </StyledButton>
      )}
    </Container>
  );
};

export default UserActions;
