import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { fileToUrl } from '../../utils/fileUtils';
import { imageUploadService, UploadContext } from '../utils/imageUploadService';
import { imageService } from './imageService';

export interface ImageDto {
  id: string;
  file?: File;
  localUrl?: string;
  url?: string;
  safe: boolean;
  isUploading: boolean;
}

const parseUrlsToImageDtos = (imgUrls: string[]): ImageDto[] =>
  imgUrls.map(url => {
    return {
      id: uuid(),
      url: url,
      isUploading: false,
      safe: true,
    };
  });

export const useUploadImages = (context: UploadContext, imgUrls?: string[]) => {
  const [photos, setPhotos] = useState<ImageDto[]>(parseUrlsToImageDtos(imgUrls || []));

  const addPhoto = async (file: File) => {
    const id = uuid();
    const localUrl = await fileToUrl(file);
    const photo: ImageDto = { id, file, localUrl, safe: true, isUploading: true };
    setPhotos(prevState => [...prevState, photo]);

    const tempUrl = await imageUploadService.getTempUrl(context);
    await imageUploadService.uploadImageForTempUrl(tempUrl, file);
    const imageUrl = `${tempUrl.url}${tempUrl.fields.key}`;
    const { data: imageRecognitionResult } = await imageService.getImageContent(imageUrl, true);

    setPhotos(prevState =>
      prevState.map(photoItem => {
        if (photoItem.id === photo.id) {
          return {
            ...photoItem,
            url: imageRecognitionResult.imageUrl,
            safe: imageRecognitionResult.safe,
            isUploading: false,
          };
        }
        return photoItem;
      })
    );
  };

  const deletePhoto = (id: string) => {
    setPhotos(prevState => prevState.filter(photo => photo.id !== id));
  };

  const movePhoto = (from: number, to: number) => {
    setPhotos(prevPhotos => {
      const updatedPhotos = [...prevPhotos];
      if (from < 0 || to < 0 || from >= updatedPhotos.length || to >= updatedPhotos.length) return updatedPhotos;

      const [movedPhoto] = updatedPhotos.splice(from, 1);
      updatedPhotos.splice(to, 0, movedPhoto);

      return updatedPhotos;
    });
  };

  const setPhotoUrls = (urls?: string[]) => {
    setPhotos(parseUrlsToImageDtos(urls || []));
  };

  return { photos, setPhotoUrls, addPhoto, deletePhoto, movePhoto };
};
