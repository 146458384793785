import React from 'react';
import { useTranslation } from 'react-i18next';
import { useItemForm } from '../../hooks/ItemContext';

import FormRow from '../../../../components/forms/FormRow';
import LocationInput from '../../../../components/forms/LocationInput';

const LocationInputRow = () => {
  const { t } = useTranslation('items');
  const { formData, setItemLocation } = useItemForm();

  return (
    <FormRow label={t('add-item.location')}>
      <LocationInput
        location={formData.itemLocation}
        setLocation={setItemLocation}
        userLocation={formData.user?.location}
      />
    </FormRow>
  );
};

export default LocationInputRow;
