import {
  FundraiserDetailsDto,
  FundraiserFilterDto,
  UserDetailsDto,
  UserFilterDto,
} from '../../../services/User/userService.dto';
import { userService } from '../../../services/User/userService';
import { isNumeric } from '../../../utils/textUtils';
import { EmployeeWithPublicDataDto } from '../../../providers/employeeType';

export const filterConfirmedUsers = async (searchValue: string): Promise<UserDetailsDto[]> => {
  const filter: UserFilterDto = {
    page: 0,
    size: 10,
    sort: 'created,desc',
    status: 'CONFIRMED',
    searchedWords: searchValue.replaceAll(' ', ','),
  };
  return userService.fetchUsers(filter, false).then(response => response.data.content || []);
};

export const filterFundraisers = async (searchValue: string): Promise<FundraiserDetailsDto[]> => {
  const filter: FundraiserFilterDto = {
    page: 0,
    size: 10,
    sort: 'id,desc',
    email: isNumeric(searchValue) ? undefined : searchValue,
    id: isNumeric(searchValue) ? Number.parseInt(searchValue) : undefined,
  };
  return userService.fetchFundraisers(filter).then(response => response.data.content || []);
};

export const filterEmployees = async (
  employees: EmployeeWithPublicDataDto[],
  searchValue: string
): Promise<EmployeeWithPublicDataDto[]> => {
  const searchWords = searchValue.split(' ');
  return employees.filter(employee => searchWords.every(searchWord => matchesEmployee(employee, searchWord)));
};

const matchesEmployee = (employee: EmployeeWithPublicDataDto, searchValue: string) =>
  stringContains(searchValue, employee.firstName) ||
  stringContains(searchValue, employee.lastName) ||
  numberEquals(searchValue, employee.id);

const stringContains = (searchValue: string, value?: string) =>
  (value?.toUpperCase().indexOf(searchValue.toUpperCase()) ?? -1) >= 0;

const numberEquals = (searchValue: string, value?: number) => value?.toString() === searchValue;
