import React from 'react';
import { useTranslation } from 'react-i18next';
import FormRow from '../../../../components/forms/FormRow';
import { useCampaignForm } from '../../hooks/CampaignContext';
import { FundraiserDetailsDto, UserDetailsDto } from '../../../../services/User/userService.dto';
import FundraiserSelector from '../../../users/components/userSelector/FundraiserSelector';

const UserInputRow = () => {
  const { t } = useTranslation('campaigns');
  const { formData, setUser } = useCampaignForm();
  const { user } = formData;

  const handleUserSelected = (user?: UserDetailsDto | FundraiserDetailsDto) => {
    setUser(user as FundraiserDetailsDto);
  };

  return (
    <FormRow label={t('add-campaign.user')}>
      <FundraiserSelector user={user} onUserSelected={handleUserSelected} />
    </FormRow>
  );
};

export default UserInputRow;
