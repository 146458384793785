import React from 'react';
import Navigation from './navigation/Navigation';
import { AuthProvider } from './providers/AuthProvider';
import GlobalStyle from './assets/styles/GlobalStyle';
import { GlobalDataProvider } from './providers/GlobalDataProvider';
import { PopupProvider } from './providers/PopupProvider';

function App() {
  return (
    <>
      <GlobalStyle />
      <AuthProvider>
        <GlobalDataProvider>
          <PopupProvider>
            <Navigation />
          </PopupProvider>
        </GlobalDataProvider>
      </AuthProvider>
    </>
  );
}

export default App;
