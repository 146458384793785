import { AxiosPromise } from 'axios';
import { apiService, apiUrl } from '../apiService';
import PageableType from '../utils/pageableType';
import { AuctionDto, AuctionFilterDto, UpdateAuctionDto } from './auctionService.dto';

class AuctionService {
  static BASE_URL = `${apiUrl}/api/item`;

  fetchAuctions(auctionFilter: AuctionFilterDto, status?: string[]): AxiosPromise<PageableType<AuctionDto>> {
    let url = `${AuctionService.BASE_URL}/admin/auctions`;

    if (!!status && status.length > 0) {
      const statusQuery = status.map(it => `auctionStatus=${it}`).join('&');
      url += `?${statusQuery}`;
    }

    return apiService.get(url, auctionFilter);
  }

  updateWinningBid(auctionId: number, bidId: number): AxiosPromise<PageableType<AuctionDto>> {
    const url = `${AuctionService.BASE_URL}/admin/auctions/${auctionId}/winner`;
    return apiService.patch(url, {}, {}, { winningBidId: bidId });
  }

  editAuction(auctionId: number, auctionDetails: UpdateAuctionDto): AxiosPromise<AuctionDto> {
    const url = `${AuctionService.BASE_URL}/admin/auctions/${auctionId}`;
    return apiService.patch(url, {}, {}, auctionDetails);
  }

  fetchAuctionDetails(itemId: number): AxiosPromise<AuctionDto> {
    const url = `${AuctionService.BASE_URL}/public/auctions?itemId=${itemId}`;
    return apiService.get(url);
  }
}

const auctionService = new AuctionService();

export { auctionService, AuctionService };
