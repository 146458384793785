import { AxiosPromise } from 'axios';
import { apiService, apiUrl } from '../apiService';
import PageableType from '../utils/pageableType';
import {
  CampaignAdminDto,
  CampaignCategoryDto,
  CampaignFilterDto,
  CommentDto,
  EditCampaignDto,
  NewCampaignDto,
} from './campaignService.dto';

class CampaignService {
  static BASE_URL = `${apiUrl}/api/campaign`;

  fetchComment(id: number): AxiosPromise<CommentDto> {
    const url = `${CampaignService.BASE_URL}/admin/comments/${id}`;
    return apiService.get(url);
  }

  fetchCampaigns(filter: CampaignFilterDto): AxiosPromise<PageableType<CampaignAdminDto>> {
    const url = `${CampaignService.BASE_URL}/admin/campaigns`;
    return apiService.get(url, filter);
  }

  fetchSingleCampaign(id: number): Promise<CampaignAdminDto> {
    const url = `${CampaignService.BASE_URL}/admin/campaigns`;
    return apiService.get(url, { page: 0, size: 1, id: id }).then(response => {
      return response?.data?.content[0];
    });
  }

  fetchCategories(): AxiosPromise<CampaignCategoryDto[]> {
    const url = `${CampaignService.BASE_URL}/public/categories`;
    return apiService.get(url);
  }

  updateSortingBoost(campaignId: number, adminSortingBoost: number): AxiosPromise<void> {
    const url = `${CampaignService.BASE_URL}/admin/campaigns/${campaignId}/admin-sorting-boost`;
    return apiService.patch(url, {}, {}, { adminSortingBoost });
  }

  addCampaignByEmployee(campaignDetails: NewCampaignDto, userId: number): AxiosPromise<CampaignAdminDto> {
    const url = `${CampaignService.BASE_URL}/admin/user/${userId}/campaigns`;
    return apiService.post(url, {}, {}, campaignDetails);
  }

  editCampaignByEmployee(campaignDetails: EditCampaignDto): AxiosPromise<CampaignAdminDto> {
    const url = `${CampaignService.BASE_URL}/admin/campaigns`;
    return apiService.put(url, {}, {}, campaignDetails);
  }
}

const campaignService = new CampaignService();

export { campaignService, CampaignService };
