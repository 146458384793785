import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { ChannelStatus } from '../../../../services/Notification/notificationService.dto';
import ChannelCell from './ChannelCell';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  padding: 0 5px;
  display: flex;
  align-items: center;
`;

interface Props {
  status?: ChannelStatus;
  communicationId?: string;
  onEditClick: () => void;
}

const SlackCell = ({ onEditClick, ...channelProps }: Props) => {
  return (
    <Container>
      <ChannelCell {...channelProps} />
      <ButtonContainer onClick={onEditClick}>
        <EditIcon />
      </ButtonContainer>
    </Container>
  );
};

export default SlackCell;
