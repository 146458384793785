import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FormRow from '../../../../components/forms/FormRow';
import { useCampaignForm } from '../../hooks/CampaignContext';
import DateInputWithLabel, { DateTimePickerFormat } from '../../../../components/inputs/DateInputWithLabel';

const Container = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledDateInput = styled(DateInputWithLabel)`
  flex: 1;

  & .MuiOutlinedInput-root {
    width: 240px;
  }
`;

const TimelineInputRow = () => {
  const { t } = useTranslation('campaigns');
  const { formData, setTimelineFrom, setTimelineTo } = useCampaignForm();

  return (
    <FormRow label={t('add-campaign.timeline')}>
      <Container>
        <StyledDateInput
          label={t('add-campaign.timeline-from')}
          pickerFormat={DateTimePickerFormat.DATE}
          value={formData.timelineFrom}
          minDate={!formData.id ? new Date() : undefined}
          onChange={value => setTimelineFrom(value || undefined)}
        />
        <StyledDateInput
          label={t('add-campaign.timeline-to')}
          pickerFormat={DateTimePickerFormat.DATE}
          value={formData.timelineTo}
          minDate={formData.timelineFrom}
          onChange={value => setTimelineTo(value || undefined)}
        />
      </Container>
    </FormRow>
  );
};

export default TimelineInputRow;
