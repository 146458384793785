import { CampaignFormData } from '../hooks/CampaignContext';
import { CampaignAdminDto } from '../../../services/Campaign/campaignService.dto';
import { PHOTOS_COUNT_LIMIT } from '../../../components/forms/PhotosInput';

export const validateCampaignForm = (formData: CampaignFormData, campaign?: CampaignAdminDto): string[] => {
  const errors: string[] = [];
  if (!formData.user) errors.push('Campaign owner required');
  if (!formData.name) errors.push('Campaign name required');
  if (!formData.categories || formData.categories.length === 0) errors.push('Campaign category required');
  if (formData.photos.length === 0) errors.push('Campaign image required');
  if (formData.photos.length > PHOTOS_COUNT_LIMIT) errors.push('Max number of images exceeded');
  if (!formData.timelineFrom) errors.push('Campaign timeline from date is required');
  if (!!formData.timelineTo && !!formData.timelineFrom && formData.timelineTo < formData.timelineFrom)
    errors.push('timeline to cannot be before timeline from');
  if (!formData.campaignLocation) errors.push('Campaign location required');
  if (!formData.campaignLocation.zipCode) errors.push('Zip code required');
  if (!formData.campaignLocation.city) errors.push('City required');
  if (!formData.campaignLocation.state) errors.push('State required');
  if (!formData.campaignLocation.latitude) errors.push('Latitude required');
  if (!formData.campaignLocation.longitude) errors.push('Longitude required');

  if (campaign) {
    if (formData.user?.id !== campaign.ownerId) errors.push('Campaign owner cannot be changed');
  }

  return errors;
};
