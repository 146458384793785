import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FormText } from '../../../components/typography/PopoverTypography';
import PasswordInputField from '../../../components/inputs/PasswordInputField';
import { ConfirmButton } from '../../../components/buttons/popoverButtons';

const ButtonsWrapper = styled.div`
  flex-direction: row;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  position: relative;
  max-width: 350px;
`;

interface Props {
  onContinuePressed: (recoveryPassword: string) => void;
}

const MIN_PASSWORD_LENGTH = 16;

const RecoveryPasswordPopover = (props: Props) => {
  const { t } = useTranslation('maintenance');
  const [password, setPassword] = useState<string>('');

  return (
    <FormContainer>
      <FormText>{t('recovery.password-popover')}</FormText>
      <ButtonsWrapper>
        <PasswordInputField onChange={event => setPassword(event.target.value)} value={password} />
        <ConfirmButton
          label={t('common:confirm-btn')}
          onClick={() => props.onContinuePressed(password)}
          disabled={password.length < MIN_PASSWORD_LENGTH}
        />
      </ButtonsWrapper>
    </FormContainer>
  );
};

export default RecoveryPasswordPopover;
