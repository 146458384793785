import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { formatRecipient } from '../data/formatRecipient';
import { RecipientDto } from '../data/adminChannelTypes';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-right: 5px;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  padding: 0 5px;
  display: flex;
  align-items: center;
`;

interface Props {
  recipient?: RecipientDto;
  onEditClick: () => void;
}

const RecipientCell = ({ recipient, onEditClick }: Props) => {
  return (
    <Container>
      <TextContainer>{formatRecipient(recipient)}</TextContainer>
      <ButtonContainer onClick={onEditClick}>
        <EditIcon />
      </ButtonContainer>
    </Container>
  );
};

export default RecipientCell;
